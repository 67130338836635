import React from "react";
import { ReactSVG } from "react-svg";
import PageHeading from "@common/PageHeading/PageHeading";
import "./TechnologySection.scss";

const TechnologyListSection = ({ technologies }) => (
    <div className="technologies-section">
        <div className="technologies-section__heading">
            <PageHeading heading="Technologies" />
        </div>
        <ul className="technologies-section__list">
            {technologies.map((technology, index) => (
                <li className="technologies-section__list-item" key={index}>
                    <ReactSVG
                        src={technology.technologyIcon}
                        alt={technology.technologyIconAlt}
                        className="technologies-section__list-item-icon"
                    />
                </li>
            ))}
        </ul>
    </div>
);

export default TechnologyListSection;
